.backlink {
  position: relative;
  margin-top: -1rem;
  padding-top: 0rem;
  margin-bottom: 1rem;
   text-transform: capitalize;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  color: #9e9e9e;
  text-align: left;

  &:hover {
    text-decoration: none;
    color: #17aee5;
  }

  &:focus {
    outline: none;
    color: #17aee5;
  }
}
