.file-container {
  display: flex;
  background-color: #f3f3f3;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: relative;
  margin: 0.8rem;
  overflow: hidden;

  // For larger screens, set the width to 30% or more
  @media (min-width: 768px) {
    width: 30%;
  }

  &::before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .filename {
    font-weight: 400;
    font-size: 0.8rem;
    word-wrap: break-word;
    position: absolute;
    bottom: -0.8rem;
    margin-left: 0.7rem;
    z-index: 2;
    color: #f3f3f3;
    max-width: 90%; // Adjust this value to fit your design
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .file-preview {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: radial-gradient(
        circle,
        transparent,
        rgba(0, 0, 0, 0.4)
      );
      pointer-events: none; // To ensure the content underneath remains clickable
    }

    img,
    embed,
    video,
    audio {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .resize {
    width: 1.5rem;
    height: 1.5rem;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #0000007a;
    border-radius: 50%;
    padding: 1.2rem;
  }

  .file-button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    z-index: 2;
    padding: 1rem;

    .action-button {
      border-radius: 50%;
      background-color: #fff;
      border: none;
      color: #333;
      padding: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.5rem;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: #45a049;
      }

      &:nth-child(2) {
        background-color: #f44336;

        &:hover {
          background-color: #e33326;
        }
      }
    }
  }

  audio{
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #0000007a;
    padding: 1.2rem;
  }

  .faFile {
    color: #333;
    font-size: 3rem;
  }
}
