.title{
    font-family: Inter;
    font-weight: 900;
    font-size: 1.5rem;
    color: #1B2040;
    text-align: center;
    line-height: 1.5rem;
    margin-top: 1rem;
    padding: 0;

  }

.qr-code{
    margin: 1rem;
}

.qr-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.link{
    position: absolute;
    max-width: 100%;
    bottom: 0;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;


    small{
        max-width: 100vw;
        //wrap text
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
    }
}