.inline{
    display: inline-flex;
    margin: none;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    button{
        margin-right: 0.5rem ;
    }
}

small{
    font-size: 0.8rem;
    font-weight: 500;
    color: #9E9E9E;
}


.main_text{
    display: inline-flex;
    font-size: 1rem;
    font-weight: 600;
}

.row-data-container{
    line-height: 14px;
}